import React from "react"
import { graphql } from "gatsby"
import "../styles/main.scss"
import { CardColumns } from "../components/CardColumns"
import ArticleCard, { Article } from "../components/ArticleCard"
import SEO from "../components/seo"
import CategoryLabel from "../components/CategoryLabel"
import { Skeleton } from "../components/layout/Skeleton"

export default function Home({
  data,
}: {
  data: {
    allArticle: {
      nodes: Article[]
    }
  }
}) {
  const articles = data.allArticle

  return (
    <>
      <SEO />
      <Skeleton>
        <CategoryLabel title={`Artykuły`} />
        <CardColumns>
          {articles.nodes.map((article: Article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </CardColumns>
      </Skeleton>
    </>
  )
}

export const query = graphql`
  query {
    allArticle(sort: { fields: [likes], order: DESC }, limit: 48) {
      nodes {
        ...ArticleNodes
      }
    }
  }
`
